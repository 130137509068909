.tabBarH .van-tabbar {
  height: 60px;
  z-index: 2;
}
.tabBarH .van-tabbar-item__icon img {
  height: 20px;
}
.tabBarH .van-tabbar-item__text {
  margin-top: 3px;
}
