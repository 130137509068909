.y_button {
  width: 100%;
  height: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.y_button .btn {
  width: 80%;
  height: 0.9rem;
  font-size: 0.35rem;
  font-weight: 600;
  background: linear-gradient(#FFDC58, #FFC305);
  border-radius: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #C86400;
}
.y_button .btn.blue {
  background: var(--bgColor);
  color: #fff;
}
