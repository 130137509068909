.y_button{
    width: 100%;
    height: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn{
        width: 80%;
        height:.9rem;
        font-size: .35rem;
        font-weight: 600;
        background: linear-gradient(#FFDC58,#FFC305);
        border-radius: .9rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #C86400;
    }
    .btn.blue{
        background:var(--bgColor);
        color: #fff;
    }
}
@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;