.go_home{
    position: absolute;
    // top: 3.2px;
    // left: 3.2px;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: 1px solid var(--themeColor);
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    .iconfont{
        font-size: .7rem;
        color: var(--themeColor);
    }
    z-index: 2;
}
@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;