.nav_bar_por{
    width: 100%;
    height: 1rem;
    position: relative;

    .nav_bar{
        width: 100%;
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #5056FD!important;
        font-size: .35rem;
        color: #ffffff;
        z-index: 2;
        position: fixed;
        top: 0;
        left: 0;
        &.transparent_nav_bar{
            background: transparent;
        }
        // 这里要精准选定 否则影响 插槽里的 iconfont
        .iconfont.icon-zuo{
            position: absolute;
            left: .2rem;
        }

        // 右侧
        .right{
            position: absolute;
            right: .2rem;
            height: 1rem;
            line-height: 1rem;
            top: 0;
            display: flex;
            align-items: center;
        }
    }

    
}
@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;