.nav_bar_por {
  width: 100%;
  height: 1rem;
  position: relative;
}
.nav_bar_por .nav_bar {
  width: 100%;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5056FD !important;
  font-size: 0.35rem;
  color: #ffffff;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
}
.nav_bar_por .nav_bar.transparent_nav_bar {
  background: transparent;
}
.nav_bar_por .nav_bar .iconfont.icon-zuo {
  position: absolute;
  left: 0.2rem;
}
.nav_bar_por .nav_bar .right {
  position: absolute;
  right: 0.2rem;
  height: 1rem;
  line-height: 1rem;
  top: 0;
  display: flex;
  align-items: center;
}
