.go_home {
  position: absolute;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 1px solid var(--themeColor);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.go_home .iconfont {
  font-size: 0.7rem;
  color: var(--themeColor);
}
