


















































































.tabBarH {
    .van-tabbar {
        height: 60px;
        z-index: 2;
    }

    .van-tabbar-item__icon img {
        height: 20px;
    }

    .van-tabbar-item__text {
        margin-top: 3px;
    }
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;