@import "~assets/css/iconfont.css";
#app {
  --bgColor: linear-gradient(to right, #458ff8, #1464E1);
  --themeColor: #2D82FA;
  --bdColor: #eee;
  --qianlan: #C8DCFF;
  font-size: 0.3rem;
  width: 100%;
  height: 100%;
}
